import React from 'react';
import Layout from '../../components/layout/Layout';

import ToastedBuns from '../../images/toastedbunslanding.jpg';
import Gatsby from '../../images/gatsby.png';

export default () => {
  return (
    <Layout pageTitle="Toasted Buns Blog/Podcast">
      <h1 className="text-center green-text">Toasted Buns blog & podcast</h1>
      <p className="text-center">
        A statically generated SEO friendly website that hosts the weekly
        podcast ToastedBuns, the site also shows blog posts created by my friend
        Peter
      </p>
      <hr className="horizontal-ruler" />
      <div className="text-center">
        <img src={ToastedBuns} alt="toasted buns" />
      </div>
      <p className="text-center">
        <a
          href="https://toastedbuns.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit site <i className="fas fa-external-link-alt" />
        </a>
      </p>
      <h2 className="green-text">Goal</h2>
      <p>
        Myself and my friend Peter decided to start a podcast for fun every
        Sunday evening so I can took on the task of creating a website for it.
      </p>
      <p>The website should allow users to:</p>
      <ul className="bullet-points">
        <li>Read all blog posts.</li>
        <li>Listen to all the episodes of our podcast.</li>
        <li>Browse blog posts and podcast by category (tags).</li>
        <li>Find all our social media links.</li>
        <li>Subscribe to our newsletter.</li>
        <li>Watch our Youtube videos.</li>
      </ul>
      <h2 className="green-text">Method</h2>
      <p>
        ToastedBuns does not need a database or any user login/register
        functionality, just some way of storing the blog posts and the podcast
        sound files.
      </p>
      <p>
        It being a blog made focusing on SEO a priority, therefore choosing a
        solution that optimises for SEO and crawlability was paramount.
      </p>
      <p>
        However, having it still be an SPA would make its UX much more seamless.
      </p>
      <p>
        A solution that covered both was to implement a statically generated
        single page application. Said application must address meta tags,
        keywords, page load speed and all the other SEO checklist points.
      </p>
      <h2 className="green-text">Tech</h2>
      <br />
      <ul className="tech-used">
        <li>
          <a
            href="https://www.gatsbyjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Gatsby} alt="gatsby" title="Gatsby" />
          </a>
        </li>
        <li>
          <a
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/245px-React.svg.png"
              alt="react"
              title="React"
            />
          </a>
        </li>
        <li>
          <a
            href="https://aws.amazon.com/s3/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://nr-platform.s3.amazonaws.com/uploads/platform/published_extension/branding_icon/275/AmazonS3.png"
              alt="aws s3"
              title="AWS S3"
            />
          </a>
        </li>
        <li>
          <a
            href="https://aws.amazon.com/cloudfront/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cdn-blog.lawrencemcdaniel.com/wp-content/uploads/2018/09/10071831/aws-cloudfront-icon.png"
              alt="cloudfront"
              title="AWS CloudFront"
            />
          </a>
        </li>
        <li>
          <a
            href="https://circleci.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://stickker.net/wp-content/uploads/2016/01/circleci.svg_.png.png"
              alt="circleci"
              title="CircleCI"
            />
          </a>
        </li>
        <li>
          <a
            href="https://sass-lang.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://proxy.duckduckgo.com/iu/?u=http%3A%2F%2Fmaddesigns.de%2Frwd-sass-compass%2Fimg%2Fsass-logo-new.png&f=1"
              alt="Sass"
              title="Sass"
            />
          </a>
        </li>
      </ul>
    </Layout>
  );
};
